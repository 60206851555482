var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "white pt-0" },
    [_c("home", { attrs: { "login-action": _vm.loginAction } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }