// src/plugins/auth.js

import authService from '../auth/authService'
import $axios from '../backend'

export default {
    install(Vue) {
        Vue.prototype.$auth = authService

        Vue.prototype.$axios = $axios

        Vue.mixin({
            created() {
                if (this.handleLoginEvent) {
                    authService.addListener('loginEvent', this.handleLoginEvent)
                }
            },

            destroyed() {
                if (this.handleLoginEvent) {
                    authService.removeListener('loginEvent', this.handleLoginEvent)
                }
            }
        })
    }
}
