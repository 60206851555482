<template>
    <v-app id="inspire" style="background-color: #fff;">
        <core-app-bar v-if="showAppBar" :loginAction="goToLoginPage" />
        <TheToolbar v-else-if="userInfo && $auth.isAuthenticated() && $route.name !== 'app-open'" />
        <core-view v-if="$route.name === 'welcome'" :login-action="goToLoginPage" />
        <router-view name="permanent-sidebar"></router-view>
        <v-main :style="$route.params.aid !== 'undefined' ? 'overflow-y: hidden' : ''" v-if="$route.name !== 'welcome'">
            <transition name="fadeonly" mode="out-in">
                <router-view></router-view>
            </transition>
        </v-main>
        <TheFooter v-if="isPublicName" />
        <v-snackbar text color="primary" :value="$store.state['snackBar']" :timeout="-1">
            <div class="d-flex align-center">
                <v-icon class="mr-3" :color="snackBarIcon === 'check_circle' ? 'success' : snackBarIcon === 'error' ? 'error' : 'info'">{{
                    snackBarIcon
                }}</v-icon>
                <div v-html="snackBarText"></div>
                <v-spacer />
                <v-btn class="font-weight-bold" text @click="$store.dispatch('hideSnackBar')">Close</v-btn>
            </div>
        </v-snackbar>
        <GlobalDialog />
    </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { publicNames, parseURL } from './utils'
import CoreAppBar from '@/components/core/AppBar'
import CoreView from '@/components/core/View'
import regionsMixin from '@/mixins/regions'

const TheToolbar = () => import('@/components/TheToolbar.vue')
const TheFooter = () => import('@/components/core/TheFooter')
const GlobalDialog = () => import('@/components/GlobalDialog')

export default {
    name: 'app',
    mixins: [regionsMixin],
    data() {
        return {
            statusUpdater: null
        }
    },
    async created() {
        try {
            await this.$auth.renewTokens()
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        handleLoginEvent(e) {
            this.$store.dispatch('setIsNotLoggedIn', e.loggedIn)
            if (e.loggedIn === true) {
                this.$store.dispatch('appStore/updateDeploymentStatus')
                this.$store.dispatch('userStore/fetchUserTasks')
                // this.$store.dispatch('userStore/setUserTaskPollingInterval')
                this.$store.dispatch('getUserInfo')
                this.$store.dispatch('fetchUserOrgs', false)
                this.$store.dispatch('appStore/fetchRecentApps', this.$route.params.oid)
            }
        },
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height
        },
        enter(element) {
            const { height } = getComputedStyle(element)

            element.style.height = this.prevHeight

            setTimeout(() => {
                element.style.height = height
            })
        },
        afterEnter(element) {
            element.style.height = 'auto'
        },
        goToLoginPage() {
            location.href = regionsMixin.methods.getIsProduction() ? 'https://az.nuvolos.cloud/login' : 'https://az-staging.nuvolos.cloud/login'
        }
    },
    mounted() {
        window.Intercom('boot', {
            app_id: 'lliic4ws'
        })
        if (this.$auth.isAuthenticated()) {
            this.$store.dispatch('appStore/updateDeploymentStatus')
            this.$store.dispatch('getUserInfo')
        }
        this.$data.statusUpdater = setInterval(() => {
            if (this.$auth.isAuthenticated()) {
                this.$store.dispatch('appStore/updateDeploymentStatus')
            }
        }, 10000)
    },
    destroyed() {
        clearInterval(this.$data.statusUpdater)
    },
    computed: {
        isPublicName() {
            return publicNames.indexOf(this.$route.name) > -1
        },
        showAppBar() {
            if (['welcome', 'privacy-policy', 'pricing', 'trial'].includes(this.$route.name)) {
                return true
            }
            return false
        },
        ...mapGetters('orgStore', ['isOrgManager', 'isOrgFaculty']),
        ...mapState(['userOrgs', 'userInfo', 'fetchingUserInfo']),
        ...mapState('snapshotStore', ['applications']),
        ...mapState(['snackBarText', 'snackBarIcon']),
        ...mapGetters('spaceStore', ['isSpaceAdmin']),
        parseCurrentUrl() {
            if (window.location.href.length) {
                return parseURL(window.location.href)
            }
            return null
        }
    },
    watch: {
        $route(to, from) {
            if (to.params.snid === undefined && from !== undefined && from.params.snid !== undefined) {
                this.$store.dispatch('snapshotStore/resetSnapshotState')
            }
            if (
                (to.params.iid && from.params.iid && to.params.iid.toString() !== from.params.iid.toString()) ||
                (from.params.iid && to.params.iid === undefined)
            ) {
                this.$store.dispatch('instanceStore/resetInstanceState')
            }
            if (
                (to.params.sid && from.params.sid && to.params.sid.toString() !== from.params.sid.toString()) ||
                (from.params.sid && to.params.sid === undefined)
            ) {
                this.$store.dispatch('spaceStore/resetSpaceState')
            }
            if (to.name === 'home-dashboard') {
                this.$store.dispatch('appStore/fetchRecentApps', this.$route.params.oid)
            }
            if (to.name === 'user-ssh-keys') {
                this.$store.dispatch('userStore/fetchSSHKeys')
            }
            if (
                (to.params.iid && from.params.iid && to.params.iid.toString() !== from.params.iid.toString()) ||
                (to.params.iid && from.params.iid === undefined && to.params.iid !== from.params.iid)
            ) {
                this.$store.dispatch('instanceStore/fetchInstanceSnapshots', to.params.iid)
                this.$store.dispatch('instanceStore/setSnapshotPollingInterval', to.params.iid)
                this.$store.dispatch('appStore/fetchAllUserApps', { iid: to.params.iid, startFirstApp: false })
            }
            if (
                (to.params.sid && from.params.sid && to.params.sid.toString() !== from.params.sid.toString()) ||
                (to.params.sid && from.params.sid === undefined && to.params.sid !== from.params.sid)
            ) {
                this.$store.dispatch('spaceStore/fetchSpaceInstances', to.params.sid)
            }
            if (
                (to.params.oid && from.params.oid && to.params.oid.toString() !== from.params.oid.toString()) ||
                (to.params.oid && from.params.oid === undefined && to.params.oid !== from.params.oid)
            ) {
                const orgData = this.userOrgs.filter(org => org.oid.toString() === to.params.oid.toString())[0]
                this.$store.dispatch('setCurrentOrgData', orgData)
                this.$store.dispatch('orgStore/fetchOrgSpaces', to.params.oid)
                this.$store.dispatch('orgStore/fetchOrgTokens', to.params.oid)
            }
            // hide scrollbar and intercom for apps
            if (to.params.aid !== undefined) {
                document.documentElement.style.overflowY = 'hidden'
                window.Intercom('update', {
                    hide_default_launcher: true
                })
            } else {
                document.documentElement.style.overflowY = 'auto'
                window.Intercom('update', {
                    hide_default_launcher: false
                })
            }
        }
    },
    components: {
        TheToolbar,
        TheFooter,
        CoreAppBar,
        CoreView,
        GlobalDialog
    }
}
</script>

<style lang="scss">
@import '@/sass/nuvolos.scss';
</style>
