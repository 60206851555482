import Vue from 'vue'
import vuetify from './plugins/vuetify'
import './plugins/components'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import router from './router'
import store from './store'
import './filters'

import { sync } from 'vuex-router-sync' // done. Returns an unsync callback fn

// Import the plugin here
import AuthPlugin from './plugins/auth'

import LogRocket from 'logrocket'
LogRocket.init('unkwc1/nuvolos', {
    network: {
        requestSanitizer: request => {
            // do not store app password
            if (request.url.toLowerCase().indexOf('start_app') !== -1) {
                if (request.body) {
                    const body = JSON.parse(request.body)
                    if (body.app_pwd) {
                        body.app_pwd = 'redacted'
                        request.body = JSON.stringify(body)
                    }
                }
            }

            return request
        }
    }
})

sync(store, router)

// Install the authentication plugin here
Vue.use(AuthPlugin)

// Use vue ClipBoard
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.filter('dateTimeToHuman', function(value) {
    var removeCommonStrings = value
        .replace(/\+00:00$/, '')
        .replace(/\+0000$/, '')
        .replace(/\.[0-9]+$/, '')
        .replace(/\.[0-9]+\s+$/, '')
    if (removeCommonStrings.includes('T')) {
        return removeCommonStrings.replace('T', ' / ')
    } else {
        return removeCommonStrings.replace(' ', ' / ')
    }
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
