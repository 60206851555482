const TheUserMain = () => import('../views/TheUserMain')
const TheUserProfile = () => import('../views/TheUserProfile')
const TheUserSSHKeys = () => import('../views/TheUserSSHKeys')
const TheUserSnowflakeTokens = () => import('../views/TheUserSnowflakeTokens')
const TheUserSideBar = () => import('../components/TheUserSideBar')
const TheUserHPC = () => import('../views/TheUserHPC')
const TheUserTasks = () => import('../views/TheUserTasks.vue')
const TheUserDropboxLink = () => import('../views/TheUserDropboxLink.vue')
export default [
    {
        path: '/user',
        name: 'user-main',
        components: {
            default: TheUserMain,
            'permanent-sidebar': TheUserSideBar
        },
        children: [
            {
                path: 'profile',
                name: 'user-profile',
                components: {
                    default: TheUserProfile
                }
            },
            {
                path: 'ssh-keys',
                name: 'user-ssh-keys',
                components: {
                    default: TheUserSSHKeys
                }
            },
            {
                path: 'snowflake-tokens',
                name: 'user-snowflake-tokens',
                components: {
                    default: TheUserSnowflakeTokens
                }
            },
            {
                path: 'hpc-sync',
                name: 'hpc-file-synchronisation',
                components: {
                    default: TheUserHPC
                }
            },
            {
                path: 'user-tasks',
                name: 'user-tasks',
                components: {
                    default: TheUserTasks
                }
            },
            {
                path: 'dropbox',
                name: 'dropbox-link',
                components: {
                    default: TheUserDropboxLink
                }
            }
        ]
    }
]
