var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fff" }, attrs: { id: "inspire" } },
    [
      _vm.showAppBar
        ? _c("core-app-bar", { attrs: { loginAction: _vm.goToLoginPage } })
        : _vm.userInfo &&
          _vm.$auth.isAuthenticated() &&
          _vm.$route.name !== "app-open"
        ? _c("TheToolbar")
        : _vm._e(),
      _vm.$route.name === "welcome"
        ? _c("core-view", { attrs: { "login-action": _vm.goToLoginPage } })
        : _vm._e(),
      _c("router-view", { attrs: { name: "permanent-sidebar" } }),
      _vm.$route.name !== "welcome"
        ? _c(
            "v-main",
            {
              style:
                _vm.$route.params.aid !== "undefined"
                  ? "overflow-y: hidden"
                  : ""
            },
            [
              _c(
                "transition",
                { attrs: { name: "fadeonly", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isPublicName ? _c("TheFooter") : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: {
            text: "",
            color: "primary",
            value: _vm.$store.state["snackBar"],
            timeout: -1
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-3",
                  attrs: {
                    color:
                      _vm.snackBarIcon === "check_circle"
                        ? "success"
                        : _vm.snackBarIcon === "error"
                        ? "error"
                        : "info"
                  }
                },
                [_vm._v(_vm._s(_vm.snackBarIcon))]
              ),
              _c("div", { domProps: { innerHTML: _vm._s(_vm.snackBarText) } }),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold",
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$store.dispatch("hideSnackBar")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ]
      ),
      _c("GlobalDialog")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }