const TheHomeMain = () => import('../views/TheHomeMain')
const TheHomeDashboard = () => import('../views/TheHomeDashboard')
const TheHomeSideBar = () => import('../components/TheHomeSideBar')
const TheHomeAddEducationSpace = () => import('../views/TheHomeAddEducationSpace')
const TheHomeAddResearchSpace = () => import('../views/TheHomeAddResearchSpace')
const TheHomeAddDatasetSpace = () => import('../views/TheHomeAddDatasetSpace')
const TheHomeOrganizationUsers = () => import('../views/TheHomeOrganizationUsers')
const TheHomeOrganizationTokens = () => import('@/modules/organization/views/TheOrgTokens')
const TheHomeOrganizationInvite = () => import('../views/TheHomeOrganizationInvite')
const TheLoginLandingPage = () => import('@/views/TheLoginLandingPage')
const TheHomeEducationSpaceList = () => import('../views/TheHomeEducationSpaceList')
const TheHomeResearchSpaceList = () => import('../views/TheHomeResearchSpaceList')
const TheHomeDatasetSpaceList = () => import('../views/TheHomeDatasetSpaceList')
const Steppers = () => import('@/components/Steppers')

export default [
    {
        path: '/welcome',
        name: 'home-welcome',
        components: {
            default: TheLoginLandingPage
        }
    },
    {
        path: '/org/:oid',
        name: 'home-main',
        components: {
            default: TheHomeMain,
            'permanent-sidebar': TheHomeSideBar
        },
        children: [
            {
                path: 'dashboard',
                name: 'home-dashboard',
                components: {
                    default: TheHomeDashboard
                }
            },
            {
                path: 'education-spaces',
                name: 'home-education-spaces',
                components: {
                    default: TheHomeEducationSpaceList
                }
            },
            {
                path: 'research-spaces',
                name: 'home-research-spaces',
                components: {
                    default: TheHomeResearchSpaceList
                }
            },
            {
                path: 'dataset-spaces',
                name: 'home-dataset-spaces',
                components: {
                    default: TheHomeDatasetSpaceList
                }
            },
            {
                path: 'users',
                name: 'home-org-users',
                components: {
                    default: TheHomeOrganizationUsers
                }
            },
            {
                path: 'tokens',
                name: 'home-org-tokens',
                components: {
                    default: TheHomeOrganizationTokens
                }
            },
            {
                path: 'users/invite-members',
                name: 'home-org-invite',
                components: {
                    default: TheHomeOrganizationInvite
                }
            },
            {
                path: 'education-spaces/add-class',
                name: 'home-add-class',
                components: {
                    default: TheHomeAddEducationSpace,
                    notifications: Steppers
                }
            },
            {
                path: 'research-spaces/add-research-project',
                name: 'home-add-research-project',
                components: {
                    default: TheHomeAddResearchSpace,
                    notifications: Steppers
                }
            },
            {
                path: 'dataset-spaces/add-dataset',
                name: 'home-add-dataset',
                components: {
                    default: TheHomeAddDatasetSpace,
                    notifications: Steppers
                }
            }
        ]
    }
]
