import $axios from '@/backend.js'
import authService from '@/auth/authService'

const userStore = {
    namespaced: true,
    state: {
        sshKeys: [],
        snowflakeTokens: [],
        fetchingSSHKeys: false,
        userTasks: [],
        fetchingUserTasks: false,
        userTaskPollingInterval: null
    },
    mutations: {
        setSSHKeys: function(state, keys) {
            state.sshKeys = keys
        },
        setFetchingSSHKeys: function(state, status) {
            state.fetchingSSHKeys = status
        },
        setSnowflakeTokens: function(state, tokens) {
            state.snowflakeTokens = tokens
        },
        setUserTasks: function(state, tasks) {
            state.userTasks = tasks
        },
        setFetchingUserTasks: function(state, status) {
            state.fetchingUserTasks = status
        },
        setUserTaskPollingInterval: function(state, interval) {
            state.userTaskPollingInterval = interval
        }
    },
    actions: {
        fetchSSHKeys: function(context) {
            context.commit('setFetchingSSHKeys', true)
            $axios
                .get('tokens/ssh_keys')
                .then(response => {
                    context.commit('setSSHKeys', response.data)
                })
                .finally(() => {
                    context.commit('setFetchingSSHKeys', false)
                })
        },
        fetchSnowflakeTokens: function(context) {
            $axios.get('tokens/snowflake_access').then(response => {
                context.commit('setSnowflakeTokens', response.data)
            })
        },
        fetchUserTasks: function(context) {
            if (authService.isAuthenticated) {
                context.commit('setFetchingUserTasks', true)
                $axios
                    .get('/tasks/list?max_results=100')
                    .then(response => {
                        context.commit('setUserTasks', response.data)
                    })
                    .finally(() => {
                        context.commit('setFetchingUserTasks', false)
                    })
            }
        },
        setUserTaskPollingInterval: function(context) {
            context.commit(
                'setUserTaskPollingInterval',
                setInterval(() => {
                    context.dispatch('fetchUserTasks')
                }, 120000)
            )
        }
    }
}

export default userStore
