// src/auth/authService.js
// Login logic: Auth0

import auth0 from 'auth0-js'
import EventEmitter from 'events'
import authConfig from '../../auth_config.json'
import LogRocket from 'logrocket'
import store from 'store'

const webAuth = new auth0.WebAuth({
    audience: 'https://datahub.ac/api',
    domain: authConfig.domain,
    redirectUri: `${window.location.origin}/callback`,
    clientID: authConfig.clientId,
    responseType: 'token id_token',
    scope: 'openid profile email call:api'
})

const localStorageKey = 'loggedIn'
const loginEvent = 'loginEvent'

class AuthService extends EventEmitter {
    idToken = null
    accessToken = null
    profile = null
    tokenExpiry = null
    tokenRenewalTimeout = null

    // Starts the user login flow
    login({ customState, connection, email }) {
        store.set('nvTarget', customState.target)

        webAuth.authorize({
            appState: customState,
            connection: connection,
            login_hint: email || ''
        })
    }

    passwordLogin(email, nvTarget = '') {
        if (nvTarget) {
            store.set('nvTarget', nvTarget)
        }

        webAuth.authorize({
            connection: 'DataHub-Test',
            login_hint: email,
            appState: { target: nvTarget }
        })
    }

    // Handles the callback request from Auth0
    handleAuthentication() {
        return new Promise((resolve, reject) => {
            webAuth.parseHash((err, authResult) => {
                if (err) {
                    reject(err)
                } else {
                    this.localLogin(authResult)
                    resolve(authResult.idToken)
                }
            })
        })
    }

    localLogin(authResult) {
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        this.accessToken = authResult.accessToken

        LogRocket.identify(this.profile.sub, {
            name: this.profile.name || this.profile.given_name + ' ' + this.profile.family_name
        })

        // Convert the JWT expiry time from seconds to milliseconds
        this.tokenExpiry = new Date(this.profile.exp * 1000)

        store.set(localStorageKey, 'true')

        this.emit(loginEvent, {
            loggedIn: true,
            profile: authResult.idTokenPayload,
            state: authResult.appState || {}
        })

        this.scheduleRenewal()
        window.Intercom('boot', {
            app_id: 'lliic4ws',
            email: this.profile.email,
            user_id: this.profile.name || this.profile.given_name + ' ' + this.profile.family_name
        })
    }

    renewTokens() {
        return new Promise((resolve, reject) => {
            webAuth.checkSession({}, (err, authResult) => {
                if (err) {
                    this.emit(loginEvent, { loggedIn: false })
                    store.remove(localStorageKey)
                    reject(err)
                } else {
                    this.localLogin(authResult)
                    resolve(authResult)
                }
            })
        })
    }

    scheduleRenewal() {
        var delay = 1.5 * 60 * 60 * 1000
        if (delay > 0) {
            this.tokenRenewalTimeout = setTimeout(() => {
                console.log('Renewing tokens...')
                this.renewTokens()
            }, delay)
        }
    }

    logOut() {
        store.remove(localStorageKey)
        this.idToken = null
        this.tokenExpiry = null
        this.profile = null

        webAuth.logout({
            returnTo: 'https://nuvolos.cloud'
        })

        clearTimeout(this.tokenRenewalTimeout)
        this.emit(loginEvent, { loggedIn: false })
        window.Intercom('shutdown')
    }

    // this will return true when auth has explicitly succeeded
    isAuthenticated() {
        return Date.now() < this.tokenExpiry && store.get(localStorageKey) === 'true'
    }
}

export default new AuthService()
