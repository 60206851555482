import $axios from '@/backend.js'
import { sortArray, isEditorRole, isDistributedInstance, isMasterInstance } from '@/utils'
const getDefaultState = () => {
    return {
        instanceSnapshots: [],
        instanceUsers: [],
        canSelectAndDistributeSnapshot: false,
        fetchingSnapshots: false,
        snapshotPollingInterval: null
    }
}
const instanceStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetInstanceState(state) {
            if (state.snapshotPollingInterval) {
                clearInterval(state.snapshotPollingInterval)
            }
            Object.assign(state, getDefaultState())
        },
        setSnapshotPollingInterval: function(state, interval) {
            state.snapshotPollingInterval = interval
        },
        setSnapshots: function(state, snapshots) {
            state.instanceSnapshots = snapshots.filter(snapshot => snapshot.lock_mode_name !== 'DELETING')
        },
        setUsers: function(state, users) {
            state.instanceUsers = users
        },
        setFetching: function(state, newVal) {
            state.fetchingSnapshots = newVal
        }
    },
    actions: {
        resetInstanceState: function(context) {
            context.commit('resetInstanceState')
        },
        setSnapshotPollingInterval: function(context, iid) {
            context.commit(
                'setSnapshotPollingInterval',
                setInterval(() => {
                    context.dispatch('fetchSnapshots', iid)
                }, 300000)
            )
        },
        fetchSnapshots: function(context, iid) {
            return $axios
                .get(`instances/${iid}/snapshots`)
                .then(function(response) {
                    const sortByIdDesc = sortArray(response.data, 'snid', 'descending', false)
                    context.commit('setSnapshots', sortByIdDesc)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchInstanceSnapshots: function(context, iid) {
            context.commit('setFetching', true)
            return context.dispatch('fetchSnapshots', iid).finally(() => {
                context.commit('setFetching', false)
            })
        },
        fetchInstanceUsers: function(context, iid) {
            $axios.get(`/instances/${iid}/list_users`).then(function(response) {
                context.commit('setUsers', response.data)
            })
        },
        updateCreateAndInviteStep: function(context, newVal) {
            context.commit('updateCreateAndInviteStep', newVal)
        },
        resetCreateAndShareData: function(context) {
            context.commit('resetCreateAndShareData')
        }
    },
    getters: {
        currentInstanceDescription: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances) {
                const instances = rootState.spaceStore.spaceInstances.filter(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (instances.length) {
                    return instances[0].description
                } else {
                    return 'No description available for this instance'
                }
            } else {
                return 'No description available for this instance'
            }
        },
        instanceLongNameById: (state, getters, rootState) => iid => {
            if (iid !== undefined && iid !== null && rootState.spaceStore.spaceInstances) {
                const instances = rootState.spaceStore.spaceInstances.filter(instance => instance.iid.toString() === iid.toString())
                if (instances.length) {
                    return instances[0].long_id
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        isInstanceEditor: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances) {
                const currentInstance = rootState.spaceStore.spaceInstances.filter(
                    instance => instance.iid.toString() === rootState.route.params.iid.toString()
                )
                if (currentInstance.length && isEditorRole(currentInstance[0].role) === true) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        isDistributedInstance: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances) {
                const distributedInstance = rootState.spaceStore.spaceInstances.filter(
                    instance => instance.iid.toString() === rootState.route.params.iid.toString()
                )
                if (distributedInstance.length) {
                    if (isDistributedInstance(distributedInstance[0].long_id) === true) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
        isMasterInstance: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances) {
                const masterInstance = rootState.spaceStore.spaceInstances.filter(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (masterInstance.length) {
                    if (isMasterInstance(masterInstance[0].long_id) === true) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        },
        canDeleteInstanceOrEditFields: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances) {
                const undeletableInstance = ['distributed', 'master']
                const filteredInstance = rootState.spaceStore.spaceInstances.filter(
                    instance => instance.iid.toString() === rootState.route.params.iid.toString()
                )
                if (filteredInstance.length && undeletableInstance.indexOf(filteredInstance[0].short_id) > -1) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    }
}
export default instanceStore
