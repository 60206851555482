import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth/authService'
import store from 'store'

import homeRoutes from './modules/home/routes'
import snapshotRoutes from './modules/snapshot/routes'
import invitationRoutes from './modules/invitations/routes'
import userRoutes from './modules/user/routes'
import adminRoutes from './modules/admin/routes'
import { publicNames } from './utils'
const Callback = () => import('./views/Callback')
const TheLoginPage = () => import('./views/TheLoginPage')
const TrialThankYouPage = () => import('./views/TrialThankYouPage')
const PrivacyPolicy = () => import('./views/PrivacyPolicy')
const Pricing = () => import('./views/Pricing')
const TrialRequest = () => import('./views/TrialRequest')
const FourOFour = () => import('@/views/The404')

Vue.use(Router)

const baseRoutes = [
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/',
        name: 'welcome'
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: TheLoginPage
        }
    },
    {
        path: '/trial-thank-you',
        name: 'trial-thank-you',
        components: {
            default: TrialThankYouPage
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        components: {
            default: PrivacyPolicy
        }
    },
    {
        path: '/pricing',
        name: 'pricing',
        components: {
            default: Pricing
        }
    },
    {
        path: '/trial',
        name: 'trial',
        components: {
            default: TrialRequest
        }
    }
]

const routes = baseRoutes
    .concat(homeRoutes)
    .concat(snapshotRoutes)
    .concat(invitationRoutes)
    .concat(userRoutes)
    .concat(adminRoutes)
    .concat([
        {
            path: '*',
            name: 'notfound',
            component: FourOFour
        }
    ])

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // allow access to root, callback and invitaitons, otherwise require user being authenticated
    if (publicNames.indexOf(to.name) > -1 || auth.isAuthenticated()) {
        return next()
    }
    store.set('nvTarget', to.path)
    router.push({ name: 'login', query: to.query })
})

export default router
