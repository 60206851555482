import axios from 'axios'
import authService from './auth/authService'
import LogRocket from 'logrocket'
import axiosRetry from 'axios-retry'
import store from './store'
import regionsMixin from './mixins/regions'

var $axios = axios.create({
    baseURL: '/api',
    timeout: 300000,
    headers: { 'Content-Type': 'application/json' }
})

axiosRetry($axios, {
    retries: 2,
    retryDelay: retryCount => {
        return retryCount * 1000
    }
})

// Request Interceptor
$axios.interceptors.request.use(function(config) {
    config.headers.Authorization = `Bearer ${authService.accessToken}`
    LogRocket.track('API:' + config.method + ':' + config.baseURL + config.url)
    return config
})

$axios.interceptors.response.use(
    function(response) {
        return response
    },
    function(error) {
        const { status } = error.response
        if (error.response && error.response.data && error.response.data.code === 'user_disabled') {
            store.dispatch('hideSnackBar')
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Deactivated account',
                dialogText: 'You account has been deactivated. You can request reactivation of your account at support@nuvolos.cloud.',
                dialogAction: 'logout'
            })
        }
        if (status === 401 || (error.response && error.response.data && error.response.data.code === 'token_expired')) {
            if (!(error.response && error.response.data && error.response.data.code === 'vpn_credentials_missing')) {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Your session has ended!',
                    dialogText: 'Your session has expired, please reload the page.',
                    dialogAction: 'reload'
                })
            }
        }
        if (error.response && error.response.data && error.response.data.code === 'instance_missing') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Unavailable data',
                dialogText: 'This instance does not exist or it has been deleted.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'quick_detailed_snapshot_limit_reached') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Snapshot limit reached',
                dialogText:
                    'You have reached the maximum number of on-demand snapshots. Please delete snapshots that are no longer needed. Auto snapshots do not count against the limit.',
                dialogAction: 'dismiss'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'restoring_snapshot') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Snapshot Restoration',
                dialogText: 'A snapshot is being restored to this instance. You can work with your application once the restoration has been completed.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'already_accepting') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Accepting invitation',
                dialogText: 'This invitation has already been accepted, please continue to wait a bit and reload the page after 1-2 minutes.',
                dialogAction: 'reload'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'already_registering') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Registering account',
                dialogText: 'Registration for this invitation has already been started, please continue to wait a bit and reload the page after 1-2 minutes.',
                dialogAction: 'reload'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'max_accepts_reached') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Invitation was already accepted!',
                dialogText: 'This invitation has already been accepted in the past.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'max_registrations_reached') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Already registered!',
                dialogText:
                    'No further account registration is permitted with this invitation. Please log in with an existing account to accept the invitation.',
                dialogAction: 'dismiss'
            })
        }
        if (
            error.response &&
            error.response.data &&
            (error.response.data.code === 'invitation_has_expired' || error.response.data.code === 'invitation_revoked')
        ) {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Invitation not valid!',
                dialogText: 'This invitation has expired or has been revoked.',
                dialogAction: 'dismiss'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'invitation_not_exists') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Invitation not found!',
                dialogText: 'This invitation does not exist. Please check if link was correctly copied.',
                dialogAction: 'dismiss'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'unidentifiable_instance_request') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Unavailable data',
                dialogText: 'This instance does not exist or it has been deleted.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'space_missing') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Unavailable data',
                dialogText: 'This space does not exist or it has been deleted.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'org_missing') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Unavailable data',
                dialogText: 'This organization does not exist or it has been deleted.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'password_complexity_error') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Password complexity error',
                dialogText: 'Your password does not satisfy the password complexity constraints. Note that your password cannot contain your name.',
                dialogAction: 'dismiss'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'email_already_registered_error') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Existing account',
                dialogText: 'An account already exists with this email address. Please sign in with the existing account to accept your invitation.',
                dialogAction: 'dismiss'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'concurrency_limit_reached') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Application concurrency limit reached',
                dialogText: 'Contact one of your lecturers/TAs that the concurrency limit has been exhausted in the course.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'application_deleted') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Application deleted / moved',
                dialogText: 'This application was deleted (or an older version restored), if accessing from a bookmark, please update it.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'insufficient_instance_role') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Permission denied',
                dialogText: 'You do not have permission for this action, please check if you have the right URL.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && error.response.data.code === 'application_missing') {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Application not found',
                dialogText: 'This application does not seem to exist, please check the URL.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        if (error.response && error.response.data && /space.*_in_.*/.test(error.response.data.code)) {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Space in different region',
                dialogText: 'This space is in a different region, redirecting to it shortly. You might be required to sign in again.',
                dialogAction: 'dismiss'
            })
            const regionCodeFromErrorMessage = error.response.data.code.replace(/space.*_in_(.*)/, '$1')
            setTimeout(() => {
                document.location = regionsMixin.methods.changeRegionURL(regionCodeFromErrorMessage)
            }, 5000)
        }
        if (error.response && error.response.data && /space_.*_migrating/.test(error.response.data.code)) {
            store.dispatch('showGlobalDialog', {
                dialogTitle: 'Space is migrating',
                dialogText:
                    'This space is in the process of being migrated to a different region and is currently not accessible. Please check back a bit later.',
                dialogAction: 'returnToWelcomePage'
            })
        }
        return Promise.reject(error)
    }
)

export default $axios
