const TheAdminMain = () => import('../views/TheAdminMain')
const TheAdminSidebar = () => import('../components/TheAdminSidebar')
const TheAdminOverview = () => import('../views/TheAdminOverview')
const TheAdminAddOrganization = () => import('../views/TheAdminAddOrganization')
const TheAdminCloneSpace = () => import('../views/TheAdminCloneSpace')

export default [
    {
        path: '/',
        name: 'admin-main',
        components: {
            default: TheAdminMain,
            'permanent-sidebar': TheAdminSidebar
        },
        children: [
            {
                path: '/admin',
                name: 'admin-overview',
                components: {
                    default: TheAdminOverview
                }
            },
            {
                path: '/admin/add-org',
                name: 'admin-add-org',
                components: {
                    default: TheAdminAddOrganization
                }
            },
            {
                path: '/admin/clone-space',
                name: 'admin-clone-space',
                components: {
                    default: TheAdminCloneSpace
                }
            }
        ]
    }
]
