import Vue from 'vue'
import Vuex from 'vuex'
import store from 'store'
import orgStore from './modules/organization/store'
import homeStore from './modules/home/store'
import spaceStore from './modules/space/store'
import instanceStore from './modules/instance/store'
import snapshotStore from './modules/snapshot/store'
import appStore from './modules/application/store'
import userStore from './modules/user/store'
import $axios from './backend.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackBar: false,
        snackBarText: 'default text',
        snackBarIcon: 'check_circle',
        globalDialog: false,
        globalDialogTitle: '',
        globalDialogText: '',
        globalDialogAction: '',
        userOrgs: [],
        currentOrgUsers: [],
        fetchingOrgs: false,
        currentOrg: 0,
        currentOrgData: null,
        userInfo: null,
        snackBarTimer: null,
        isNotLoggedIn: null,
        openOrgSelectorDialog: false,
        fetchingUserInfo: false
    },
    mutations: {
        showSnackBar: function(state, { snackBarText, snackBarIcon }) {
            state.snackBar = true
            state.snackBarText = snackBarText
            state.snackBarIcon = snackBarIcon
        },
        showGlobalDialog: function(state, { dialogTitle, dialogText, dialogAction }) {
            if (state.globalDialog === false) {
                state.globalDialog = true
                state.globalDialogTitle = dialogTitle
                state.globalDialogText = dialogText
                state.globalDialogAction = dialogAction
            }
        },
        setSnackBarTimer: function(state, timer) {
            state.snackBarTimer = timer
        },
        resetSnackBarTimer: function(state) {
            if (state.snackBarTimer) {
                clearTimeout(state.snackBarTimer)
            }
        },
        setUserOrgs: function(state, userOrgs) {
            state.userOrgs = userOrgs
        },
        setFetching: function(state, val) {
            state.fetchingOrgs = val
        },
        setOpenOrgSelectorDialog: function(state, val) {
            state.openOrgSelectorDialog = val
        },
        setCurrentOrgData: function(state, newData) {
            state.currentOrgData = newData
        },
        hideSnackBar: function(state) {
            state.snackBar = false
        },
        hideGlobalDialog: function(state) {
            state.globalDialog = false
        },
        setFetchingUserInfo: function(state, status) {
            state.fetchingUserInfo = status
        },
        setUserInfo: function(state, userInfo) {
            state.userInfo = userInfo
        },
        setIsNotLoggedIn: function(state, loggedIn) {
            state.isNotLoggedIn = !loggedIn
        }
    },
    actions: {
        showSnackBar: function(context, { snackBarTimeout, snackBarText, snackBarIcon }) {
            context.commit('resetSnackBarTimer')
            context.commit('showSnackBar', { snackBarText, snackBarIcon })
            context.commit(
                'setSnackBarTimer',
                setTimeout(() => {
                    context.commit('hideSnackBar')
                }, snackBarTimeout || 10000)
            )
        },
        showGlobalDialog: function(context, { dialogTitle, dialogText, dialogAction }) {
            context.commit('showGlobalDialog', { dialogTitle: dialogTitle, dialogText: dialogText, dialogAction: dialogAction })
        },
        hideSnackBar: function(context) {
            context.commit('hideSnackBar')
        },
        hideGlobalDialog: function(context) {
            context.commit('hideGlobalDialog')
        },
        fetchUserOrgs: function(context, fetchCurrentOrgSpaces) {
            context.commit('setFetching', true)
            $axios
                .post('/orgs/find', {})
                .then(response => {
                    var orgData
                    const oids = response.data.map(org => org.oid.toString())
                    if (context.state.currentOrgData && oids.includes(context.state.currentOrgData.oid.toString())) {
                        orgData = context.state.currentOrgData
                    } else if (store.get('latestOrg') && oids.includes(store.get('latestOrg'))) {
                        const latestOrgData = response.data.filter(org => org.oid.toString() === store.get('latestOrg'))
                        orgData = latestOrgData.length ? latestOrgData[0] : response.data[0]
                    } else {
                        orgData = response.data[0]
                    }
                    context.commit('setCurrentOrgData', orgData)
                    context.commit('setUserOrgs', response.data)
                    if (fetchCurrentOrgSpaces === true) {
                        context.dispatch('orgStore/fetchOrgSpaces', orgData.oid)
                    }
                })
                .finally(() => {
                    context.commit('setFetching', false)
                })
        },
        setOpenOrgSelectorDialog: function(context, val) {
            context.commit('setOpenOrgSelectorDialog', val)
        },
        setCurrentOrgData: function(context, newData) {
            if (newData !== undefined) {
                if (context.state.userOrgs && context.state.userOrgs.length > 1 && !store.get('latestOrg')) {
                    context.commit('setOpenOrgSelectorDialog', true)
                }
                context.commit('setCurrentOrgData', newData)
                store.set('latestOrg', newData.oid.toString())
            }
        },
        getUserInfo: function(context) {
            context.commit('setFetchingUserInfo', true)
            return $axios
                .get('/users/current')
                .then(response => {
                    if (typeof response.data === 'object' && response.data.is_active === true) {
                        context.commit('setUserInfo', response.data)
                        if (response.data.email) {
                            store.set('nvLastEmail', response.data.email)
                        }
                    } else if (context.state.route.params.invid === undefined) {
                        context.commit('setUserInfo', null)
                    }
                    context.commit('setFetchingUserInfo', false)
                })
                .catch(error => {
                    context.commit('setUserInfo', null)
                    context.commit('setFetchingUserInfo', false)
                    if (context.state.route.name === 'invitation-review' && error.response && error.response.status === 404) {
                        return Promise.reject(error)
                    }
                })
        },
        setIsNotLoggedIn: function(context, loggedIn) {
            context.commit('setIsNotLoggedIn', loggedIn)
        }
    },
    modules: {
        orgStore,
        homeStore,
        spaceStore,
        instanceStore,
        snapshotStore,
        appStore,
        userStore
    }
})
