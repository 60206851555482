<template>
    <v-app-bar
        v-scroll="onScroll"
        :color="isScrolling || !['privacy-policy'].includes($route.name) ? 'transparent' : 'rgb(23, 167, 193)'"
        :flat="!isScrolling"
        dark
        fixed
        hide-on-scroll
    >
        <router-link :to="{ name: 'welcome' }">
            <v-img class="ml-3" contain max-width="110px" max-height="22px" src="@/assets/Nuvolos-transparent-white.svg" />
        </router-link>
        <v-spacer />
        <v-toolbar-items class="pa-0">
            <v-menu open-on-hover bottom offset-y rounded="0">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text>pricing</v-btn>
                </template>
                <v-list nav dense color="secondary">
                    <v-list-item :to="{ name: 'pricing' }">
                        <v-list-item-title>
                            <span class="white--text">Base packages</span>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn v-if="$vuetify.breakpoint.smAndUp" text :to="{ name: 'trial' }">Try Nuvolos</v-btn>
            <v-btn v-if="$vuetify.breakpoint.smAndUp" @click="$props.loginAction" text>Sign In</v-btn>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
// Utilities

export default {
    data: () => ({
        isScrolling: false
    }),
    methods: {
        onScroll() {
            this.isScrolling = (window.pageYOffset || document.documentElement.scrollTop || 0) > 25
        }
    },
    props: {
        loginAction: Function
    }
}
</script>
