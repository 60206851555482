import $axios from '@/backend.js'
import { sortArray } from '@/utils'
import authService from '@/auth/authService'

const appStore = {
    namespaced: true,
    state: {
        userApps: [],
        deploymentStatus: [],
        recentApps: [],
        isHiDPI: false,
        appType: 'jupyterlab',
        fetchingApps: false,
        lastStartedSessionId: null
    },
    mutations: {
        setDeploymentStatus: function(state, deployments) {
            const appsSortedById = sortArray(deployments, 'aid', 'descending', false)
            state.deploymentStatus = appsSortedById
        },
        setUserApps: function(state, apps) {
            state.userApps = apps
        },
        setRecentApps: function(state, apps) {
            state.recentApps = apps
        },
        setAppType: function(state, appType) {
            state.isHiDPI = appType.endsWith('-hidpi')
            state.appType = appType
        },
        setFetching: function(state, val) {
            state.fetchingApps = val
        },
        setLastStartedSessionId: function(state, sessionId) {
            state.lastStartedSessionId = sessionId
        }
    },
    actions: {
        fetchAllUserApps: function(context, { iid, startFirstApp }) {
            return $axios.post('/applications/list', { iid: iid }).then(response => {
                context.commit('setUserApps', response.data)
                if (startFirstApp === true) {
                    context.dispatch('startApp', response.data[0].aid)
                }
            })
        },
        fetchRecentApps: function(context, oid) {
            context.commit('setFetching', true)
            $axios
                .post('/applications/quick', { oid: oid })
                .then(response => {
                    // loop over list and find minimal iid for each aoid
                    var minIid = {}
                    for (var i = 0; i < response.data.length; i++) {
                        if (response.data[i].instance_id < (minIid[response.data[i].aoid.toString()] || Infinity)) {
                            minIid[response.data[i].aoid.toString()] = response.data[i].instance_id
                        }
                    }

                    var recentApps = []
                    for (i = 0; i < response.data.length; i++) {
                        if (response.data[i].instance_id <= minIid[response.data[i].aoid.toString()]) {
                            recentApps.push(response.data[i])
                        }
                    }
                    context.commit('setRecentApps', recentApps)
                })
                .finally(() => {
                    context.commit('setFetching', false)
                })
        },
        updateDeploymentStatus: function(context) {
            if (authService.isAuthenticated()) {
                return $axios.get('/proxy/app_status', { withCredentials: true }).then(function(response) {
                    context.commit('setDeploymentStatus', response.data)
                })
            }
        },
        startApp: function(context, { aid, appUsername, appPwd, nodePool }) {
            // check if the app supports HiDPI
            const launchedApp = context.state.userApps.filter(a => {
                return a.aid.toString() === aid.toString()
            })

            if (launchedApp.length < 1) {
                context.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application not found',
                    dialogText: 'This application does not seem to exist.',
                    dialogAction: 'returnToWelcomePage'
                })
            }

            const scaleFactor =
                window.devicePixelRatio >= 1.25 &&
                (window.innerWidth - 65) * window.innerHeight <= 1440 * 900 &&
                launchedApp.length > 0 &&
                launchedApp[0].app_type.endsWith('-hidpi')
                    ? 2
                    : 1

            return $axios
                .post(`/proxy/${aid}/start_app`, {
                    dpi: 96 * scaleFactor,
                    screen_width: (window.innerWidth - 65) * scaleFactor,
                    screen_height: window.innerHeight * scaleFactor,
                    app_username: appUsername || '',
                    app_pwd: appPwd || '',
                    node_pool: nodePool || ''
                })
                .then(response => {
                    context.commit('setLastStartedSessionId', response.data.session_id)
                    context.dispatch('updateDeploymentStatus')
                    context.dispatch(
                        'showSnackBar',
                        {
                            snackBarText: 'Starting application...',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        },
                        {
                            root: true
                        }
                    )
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.data.code !== 'vpn_credentials_missing') {
                        context.dispatch(
                            'showGlobalDialog',
                            {
                                dialogText:
                                    'We were not able to start this application - this might be a temporary issue, if it persists please reach out to support@nuvolos.cloud',
                                dialogTitle: 'Application start failed',
                                dialogAction: 'returnToWelcomePage'
                            },
                            {
                                root: true
                            }
                        )
                    } else {
                        throw new Error('vpn_credentials_missing')
                    }
                })
        },
        stopApp: function(context, { aid, sessionId }) {
            return $axios
                .post(`/proxy/${aid}/stop_app`, { session_id: sessionId || '' })
                .then(response => {
                    if ((sessionId || '') === '') {
                        window.setTimeout(() => {
                            context.dispatch('updateDeploymentStatus')
                        }, 1000)
                    }
                })
                .catch(function(error) {
                    console.log(error)
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Error stopping application. Please try again later.', snackBarTimeout: 10000 },
                        { root: true }
                    )
                })
        },
        setCurrentAppType: function(context, { iid, aid }) {
            return context.dispatch('fetchAllUserApps', { iid, startFirstApp: false }).then(() => {
                const launchedApp = context.state.userApps.filter(a => {
                    return a.aid.toString() === aid.toString()
                })
                if (launchedApp && launchedApp.length > 0) {
                    context.commit('setAppType', launchedApp[0].app_type)
                }
            })
        }
    },
    getters: {
        runningDeployments: state => {
            return state.deploymentStatus.filter(d => {
                return d.replicas === d.available_replicas
            })
        },
        startingDeployments: state => {
            return state.deploymentStatus.filter(d => d.replicas === 1 && d.available_replicas === null)
        },
        stoppedApps: state => {
            if (state.userApps) {
                const runningAppsIds = state.deploymentStatus.map(app => app.aid)
                const stoppedApps = state.userApps.filter(app => runningAppsIds.includes(app.aid.toString()) === false)
                return stoppedApps
            } else {
                return []
            }
        },
        appStatus: state => aid => {
            const matchingDeployments = state.deploymentStatus.filter(d => {
                return d.aid.toString() === aid.toString()
            })
            if (matchingDeployments.length) {
                if (matchingDeployments[0].available_replicas > 0) {
                    return 'running'
                } else {
                    return 'starting'
                }
            } else {
                return 'stopped'
            }
        }
    }
}

export default appStore
