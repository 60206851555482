<template>
    <v-main class="white pt-0">
        <home :login-action="loginAction" />
    </v-main>
</template>

<script>
import Home from '@/views/Home'
export default {
    components: {
        Home
    },
    props: { loginAction: Function },
    metaInfo() {
        return {
            titleTemplate: '%s — Alpha Theme'
        }
    }
}
</script>

<style lang="sass">
section
  padding: 64px 16px

  @media screen and (max-width: 640px)
    padding: 16px
</style>
