const regions = [
    {
        code: 'IONOS_FRANKFURT',
        domainPrefix: '',
        enum: 0,
        production: true
    },
    {
        code: 'AZ_EU_WEST',
        domainPrefix: 'az.',
        enum: 1,
        production: true
    },
    {
        code: 'IONOS_FRANKFURT_staging',
        domainPrefix: 'staging.',
        enum: 0
    },
    {
        code: 'AZ_EU_WEST_staging',
        domainPrefix: process.env.NODE_ENV === 'production' ? 'az-staging.' : 'dh-test.',
        enum: 1
    }
]

const regionsMixin = {
    methods: {
        changeRegionURL(newRegionCode, oldURL) {
            const newRegion = regions.filter(r => r.code === newRegionCode)
            if (newRegion.length > 0) {
                const urlToReplace = oldURL || location.href
                return urlToReplace.replace(location.origin, 'https://' + newRegion[0].domainPrefix + 'nuvolos.cloud')
            }
            return location.origin
        },
        getCurrentRegionEnum() {
            const cr = regions.filter(e => location.origin.match('https://' + e.domainPrefix + 'nuvolos.cloud'))
            if (cr.length > 0) {
                return cr[0].enum
            }
            return 0
        },
        getRegionCodeFromEnum(enumVal) {
            const region = regions.filter(r => r.enum.toString() === enumVal.toString() && r.production === this.getIsProduction())
            if (region.length) {
                return region[0].code
            }
            return regions[0].code
        },
        getIsProduction() {
            const currentRegion = regions.filter(e => location.origin.match('https://' + e.domainPrefix + 'nuvolos.cloud'))
            return currentRegion.length && currentRegion[0].production
        }
    }
}

export default regionsMixin
