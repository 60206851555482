var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.onScroll,
          expression: "onScroll"
        }
      ],
      attrs: {
        color:
          _vm.isScrolling || !["privacy-policy"].includes(_vm.$route.name)
            ? "transparent"
            : "rgb(23, 167, 193)",
        flat: !_vm.isScrolling,
        dark: "",
        fixed: "",
        "hide-on-scroll": ""
      }
    },
    [
      _c(
        "router-link",
        { attrs: { to: { name: "welcome" } } },
        [
          _c("v-img", {
            staticClass: "ml-3",
            attrs: {
              contain: "",
              "max-width": "110px",
              "max-height": "22px",
              src: require("@/assets/Nuvolos-transparent-white.svg")
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        { staticClass: "pa-0" },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "open-on-hover": "",
                bottom: "",
                "offset-y": "",
                rounded: "0"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c("v-btn", _vm._g({ attrs: { text: "" } }, on), [
                        _vm._v("pricing")
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "v-list",
                { attrs: { nav: "", dense: "", color: "secondary" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "pricing" } } },
                    [
                      _c("v-list-item-title", [
                        _c("span", { staticClass: "white--text" }, [
                          _vm._v("Base packages")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c("v-btn", { attrs: { text: "", to: { name: "trial" } } }, [
                _vm._v("Try Nuvolos")
              ])
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.$props.loginAction } },
                [_vm._v("Sign In")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }