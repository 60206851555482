const homeStore = {
    namespaced: true,
    state: {
        createSpaceAndApp: { operationSelected: false, step: 1, spaceType: '', skipAppCreation: false, lastStepCompleted: false }
    },
    mutations: {
        updateCreateSpaceAndApp: function(state, { operationSelectedVal, stepVal, skipAppCreationVal, lastStepCompletedVal, spaceTypeVal }) {
            if (operationSelectedVal !== null && operationSelectedVal !== undefined) {
                state.createSpaceAndApp.operationSelected = operationSelectedVal
            }
            if (stepVal !== null && stepVal !== undefined) {
                state.createSpaceAndApp.step = stepVal
            }
            if (skipAppCreationVal !== null && skipAppCreationVal !== undefined) {
                state.createSpaceAndApp.skipAppCreation = skipAppCreationVal
            }
            if (lastStepCompletedVal !== null && lastStepCompletedVal !== undefined) {
                state.createSpaceAndApp.lastStepCompleted = lastStepCompletedVal
            }
            if (spaceTypeVal !== null && spaceTypeVal !== undefined) {
                state.createSpaceAndApp.spaceType = spaceTypeVal
            }
        },
        resetCreateSpaceAndApp: function(state) {
            state.createSpaceAndApp.operationSelected = false
            state.createSpaceAndApp.lastStepCompleted = false
            state.createSpaceAndApp.step = 1
            state.createSpaceAndApp.spaceType = ''
            state.createSpaceAndApp.skipAppCreation = false
        }
    },
    actions: {
        updateCreateSpaceAndApp: function(context, { operationSelectedVal, stepVal, skipAppCreationVal, lastStepCompletedVal, spaceTypeVal }) {
            context.commit('updateCreateSpaceAndApp', {
                operationSelectedVal: operationSelectedVal,
                stepVal: stepVal,
                skipAppCreationVal: skipAppCreationVal,
                lastStepCompletedVal: lastStepCompletedVal,
                spaceTypeVal: spaceTypeVal
            })
        },
        resetCreateSpaceAndApp: function(context, newVal) {
            context.commit('resetCreateSpaceAndApp')
        }
    }
}

export default homeStore
