import $axios from '@/backend.js'
import { sortArray, isEditorRole, isDistributedInstance, isAdminRole } from '@/utils'
const getDefaultState = () => {
    return {
        spaceInstances: [],
        spaceUsers: [],
        spaceInvitations: [],
        latestNumOfInstanceWithGroupName: 0,
        instancesWithEditorRole: [],
        distributedInstanceId: null,
        fetchingInstances: false,
        invitationFromSnapshotView: false,
        fullInstanceSnapshotData: [],
        fetchingSpaceUsers: null,
        fetchingSpaceInvitations: null
    }
}
const spaceStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetSpaceState(state) {
            Object.assign(state, getDefaultState())
        },
        setInstances: function(state, instances) {
            const sortByIdDesc = sortArray(instances, 'iid', 'descending', false)
            state.spaceInstances = sortByIdDesc
        },
        setDistributedInstanceId: function(state, iid) {
            state.distributedInstanceId = iid
        },
        setFullInstanceSnapshotData: function(state, fullData) {
            const sortByIdDesc = sortArray(fullData, 'iid', 'ascending', false)
            state.fullInstanceSnapshotData = sortByIdDesc
        },
        setUsers: function(state, users) {
            state.spaceUsers = users
        },
        setSpaceInvitations: function(state, invitations) {
            state.spaceInvitations = invitations
        },
        setFetching: function(state, { fetchingObject, fetchingStatus }) {
            if (fetchingObject === 'instances') {
                state.fetchingInstances = fetchingStatus
            } else if (fetchingObject === 'spaceUsers') {
                state.fetchingSpaceUsers = fetchingStatus
            } else if (fetchingObject === 'spaceInvitations') {
                state.fetchingSpaceInvitations = fetchingStatus
            }
        },
        updateLatestNumOfInstanceWithGroupName: function(state, instances) {
            state.latestNumOfInstanceWithGroupName = 0
            const instanceLongNames = instances.map(instance => instance.long_id.toLowerCase().replace(/\s+/g, ''))
            const instanceShortNames = instances.map(instance =>
                instance.short_id
                    .toLowerCase()
                    .replace('_', '')
                    .replace(/\s+/g, '')
            )
            const instanceNames = instanceLongNames.concat(instanceShortNames)
            const instanceNamesContainingGroup = instanceNames.filter(instance => instance.slice(0, 5) === 'group' && /^\d+$/.test(instance.slice(5)))
            const instanceGroupNumbers = instanceNamesContainingGroup.map(instance => instance.slice(5))
            const sortedInstanceGroupNumbers = instanceGroupNumbers.sort((a, b) => b - a)
            if (sortedInstanceGroupNumbers.length) {
                state.latestNumOfInstanceWithGroupName = sortedInstanceGroupNumbers[0]
            }
        },
        updateInstancesWithEditorRole: function(state, instances) {
            state.instancesWithEditorRole = instances.filter(
                instance => isEditorRole(instance.role) === true && isDistributedInstance(instance.short_id) === false
            )
        }
    },
    actions: {
        resetSpaceState: function(context) {
            context.commit('resetSpaceState')
        },
        setDistributedInstanceId: function(context, instances) {
            if (instances.length) {
                const distributedInstance = instances.filter(instance => isDistributedInstance(instance.short_id) === true)
                if (distributedInstance.length) {
                    context.commit('setDistributedInstanceId', distributedInstance[0].iid)
                }
            }
        },
        fetchSpaceInstances: function(context, sid) {
            context.commit('setFetching', { fetchingObject: 'instances', fetchingStatus: true })
            return $axios
                .post(`spaces/${sid}/get_instances`, { skip_empty_viewer_instances: true })
                .then(response => {
                    const excludeDeleted = response.data.filter(instance => instance.lock_mode_name !== 'DELETING')
                    context.commit('setInstances', excludeDeleted)
                    context.commit('updateLatestNumOfInstanceWithGroupName', excludeDeleted)
                    context.commit('updateInstancesWithEditorRole', excludeDeleted)
                    context.dispatch('setDistributedInstanceId', excludeDeleted)
                    if (excludeDeleted.length) {
                        var numberInstances = excludeDeleted.length
                        var instanceSnapshots = []
                        excludeDeleted.forEach(instance => {
                            $axios
                                .get(`instances/${instance.iid}/snapshots`)
                                .then(response => {
                                    instanceSnapshots.push({ iid: instance.iid, instance_name: instance.long_id, role: instance.role, data: response.data })
                                    numberInstances = numberInstances - 1
                                })
                                .catch(() => {
                                    numberInstances = numberInstances - 1
                                })
                                .finally(() => {
                                    if (numberInstances === 0) {
                                        context.commit('setFullInstanceSnapshotData', instanceSnapshots)
                                    }
                                })
                        })
                    }
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'instances', fetchingStatus: false })
                })
        },
        fetchSpaceUsers: function(context, sid) {
            context.commit('setFetching', { fetchingObject: 'spaceUsers', fetchingStatus: true })
            $axios
                .get(`/spaces/${sid}/list_all_users`)
                .then(function(response) {
                    context.commit('setUsers', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'spaceUsers', fetchingStatus: false })
                })
        },
        fetchSpaceInvitations: function(context, sid) {
            context.commit('setFetching', { fetchingObject: 'spaceInvitations', fetchingStatus: true })
            $axios
                .post(`/spaces/${sid}/invitations`, { children_instance_invitations: true })
                .then(function(response) {
                    context.commit('setSpaceInvitations', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'spaceInvitations', fetchingStatus: false })
                })
        }
    },
    getters: {
        spaceLongNameById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const spaces = rootState.orgStore.spacesWithPrimarySnapshots.filter(space => space.sid.toString() === sid.toString())
                if (spaces.length) {
                    return spaces[0].space_long_id
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        spaceDescriptionById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const spaces = rootState.orgStore.spacesWithPrimarySnapshots.filter(space => space.sid.toString() === sid.toString())
                if (spaces.length) {
                    return spaces[0].space_description
                } else {
                    return 'No description available for this space'
                }
            } else {
                return 'No description available for this space'
            }
        },
        isSpaceAdmin: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots) {
                const currentSpace = rootState.orgStore.spacesWithPrimarySnapshots.filter(
                    space => space.sid.toString() === rootState.route.params.sid.toString()
                )
                if (currentSpace.length && isAdminRole(currentSpace[0].space_role) === true) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        currentSpaceType: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots) {
                const spaces = rootState.orgStore.spacesWithPrimarySnapshots.filter(space => space.sid.toString() === rootState.route.params.sid.toString())
                if (spaces.length > 0) {
                    return spaces[0].space_type
                } else {
                    return null
                }
            } else {
                return null
            }
        }
    }
}
export default spaceStore
