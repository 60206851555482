var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pa-0", attrs: { id: "home" } },
    [
      _c("hero", { attrs: { "login-action": _vm.loginAction } }),
      _c("welcome"),
      _c("demo"),
      _c("features"),
      _c("Testimonials"),
      _c("GetStarted")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }