const TheInvitationReview = () => import('../views/TheInvitationReview')
const TheInvitationAccept = () => import('../views/TheInvitationAccept')
const TheInvitationCheckUser = () => import('../views/TheInvitationCheckUser')
const TheInvitationGeneratorSingle = () => import('../views/TheInvitationGeneratorSingle')

export default [
    {
        path: '/invitations/review/:invid/',
        name: 'invitation-review',
        components: {
            default: TheInvitationReview
        }
    },
    {
        path: '/invitations/accept/:invid/',
        name: 'invitation-accept',
        components: {
            default: TheInvitationAccept
        }
    },
    {
        path: '/invitations/userdetails/:invid/',
        name: 'invitation-check-user',
        components: {
            default: TheInvitationCheckUser
        }
    },
    {
        path: '/enroll/:space/:token/',
        name: 'invgen-single',
        components: {
            default: TheInvitationGeneratorSingle
        }
    }
]
